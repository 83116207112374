<template>
    <div>
        <Navigation
            :fav-icon="logo"
            :breakpoint="1200"
        />

        <Nuxt />

        <client-only>
            <CookieConsent
                :content="$prismic.asHtml($store.state.footer.cookie_text)"
                :button-text="$translate('cta_accept_cookies', 'Okej')"
            />
        </client-only>

        <ScrollTop />

        <compare-lenders-popup />

        <Footer />
    </div>
</template>
<script>
import Navigation from '@/components/includes/TheNavigation.vue';
import Footer from '@/components/includes/TheFooter';
import CompareLendersPopup from '@/components/includes/CompareLendersPopup';
import { ScrollTop, CookieConsent } from '@swegaming-ab/vue-components';

export default {
    components: {
        CookieConsent,
        Navigation,
        Footer,
        ScrollTop,
        CompareLendersPopup
    },
    async middleware({ store, $prismic, route }) {
        await store.dispatch('fetchStates', {$prismic, route});
    },
    computed: {
        logo() {
            if (this.$isSweden()) {
                return require('~/static/logo-se.png');
            }

            return require('~/static/logo-no.png');
        }
    }
};
</script>
