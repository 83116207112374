<template>
    <div 
        class="nav-list-item__mega-menu" 
        :class="{active: show}"
        @mouseenter="onEnter()" 
        @mouseleave="onLeave()"    
    >
        <span class="ddm_text" @click="toggle">
            {{ megaMenu.dropdown_text }}
        </span>

        <div
            v-show="show"
            class="dropdown"
            :class="{'overflow-auto': show }"
        >
            <div class="mega-container link-column-container">
                <link-column 
                    v-for="(bodyData, index) in megaMenu.body"
                    :key="index"
                    :document="bodyData"
                    :slice-open="currentChildSliceOpen"
                />
            </div>
            <div class="highlighted-link-container">
                <nuxt-link 
                    :to="$prismic.linkResolver(megaMenu.highlighted_link)"
                    class="mega-container highlighted-link"    
                >
                    <span>{{ megaMenu.highlighted_link_text }}</span>
                    <i class="fas fa-chevron-right fa-2xs" />
                </nuxt-link>
            </div>
        </div>
    </div>
</template>

<script>
import LinkColumn from '@/components/includes/components/LinkColumn.vue';
import ExpandDropdownLogic from '@/components/includes/mixins/ExpandDropdownLogic.js';
export default {
    components: {
        LinkColumn
    },
    mixins: [
        ExpandDropdownLogic
    ],
    computed: {
        megaMenu() {
            return this.document.primary.mega_menu.data;
        }
    },
    watch: {
        show() {
            if (this.show === false) {
                this.currentChildSliceOpen = '';
            }
        }
    },
    created() {
        this.$nuxt.$on('expandedDropdown', (value) => {
            this.updateSliceId(value);
        });
    }
};
</script>