export default ({ app }, inject) => {

    inject('event', {
        ratingSubmitted: () => {
            app.$gtm.push({
                event: 'ratingSubmitted'
            });
        },
        commentSubmitted: () => {
            app.$gtm.push({
                event: 'commentSubmitted'
            });
        },
        newsletterSubscribed: () => {
            app.$gtm.push({
                event: 'newsletterSubscribed'
            });
        },
        loanWizardSubmitted: () => {
            app.$gtm.push({
                event: 'loanWizardSubmitted'
            });
        },
        viewLender: (title) => {
            app.$gtm.push({
                event: 'viewLender',
                lender: title
            });
        },
        viewCorporateLender: (title) => {
            app.$gtm.push({
                event: 'viewCorporateLender',
                lender: title
            });
        }
    });
};