<template>
    <transition name="compare">
        <div v-if="showPopup" class="wrapper">
            <ul>
                <li
                    v-for="id in addedLenders"
                    :key="id"
                >
                    <nuxt-link :to="$prismic.linkResolver(lender(id))">
                        <prismic-image
                            :img="lender(id).data.image"
                            w="50"
                        />
                        <span class="lender-title">{{ lender(id).data.title }}</span>
                    </nuxt-link>

                    <i 
                        class="fa-regular fa-xmark"
                        @click="removeLender(id)"
                    />
                </li>
            </ul>

            <nuxt-link
                :to="compareRoute"
                class="btn btn-sm btn-green compare-button"
            >
                {{ $translate('cl_compare_lenders', 'Jämför {amount} lån').replace('{amount}', addedLenders.length) }}
            </nuxt-link>
        </div>
    </transition>
</template>

<script>
export default {
    computed: {
        showPopup() {
            return this.$store.state.lendersToCompare.length;
        },
        addedLenders() {
            return [...this.$store.state.lendersToCompare].reverse();
        },
        compareRoute() {
            let comparePageRoute = this.$prismic.linkResolver(this.$store.state.settings.lender_compare_page);
            comparePageRoute = comparePageRoute.slice(-1) === '/' 
                ? comparePageRoute.slice(0, -1)
                : comparePageRoute;

            return `${comparePageRoute}?lender=${this.$store.state.lendersToCompare.join('&lender=')}`;
        }
    },
    methods: {
        lender(id) {
            return this.$store.state.lenders[id];
        },
        removeLender(id) {
            this.$store.commit('REMOVE_LENDER_FROM_COMPARE', id);
        }
    }
};
</script>

<style lang="scss" scoped>
    .wrapper {
        position: fixed;
        z-index: 99;
        bottom: 0;
        right: 0;
        width: 270px;
        max-width: 100%;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        @include border-radius(5px,(top-left));
        background: $white;
        @include border;
        @include spacing(padding,4);
        @include grid(1,10px);

        ul {
            max-height: 115px;
            @include device(pad) {
                max-height: 155px;
            }
            overflow-y: auto;
            overscroll-behavior: contain;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
            li {
                padding: 5px 0;
                min-height: 32px;
                @include flex(between);
                @include font-size(13px,6px);
                @include border(bottom);
                gap: 5px;
                flex-wrap: nowrap;
                &:last-child { border-bottom: 0; }
                a {
                    @include flex(between);
                    color: $font;
                    text-decoration: unset;
                    &:hover {
                        text-decoration: underline;
                        color: $green;
                    }
                    .lender-title {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        flex: 1;
                        padding-left: 10px;
                    }
                }

                i {
                    font-size: 18px;
                    cursor: pointer;
                }
            }
        }
        .compare-button {
            padding: 10px 20px;
            font-size: 14px;
        }
    }
    
    .compare-enter-active,
    .compare-leave-active {
        transition: all .3s;
    }
    .compare-enter,
    .compare-leave-to {
        transform: translateY(120px);
    }
</style>