import Vue from 'vue';

import { RichText, Section, PrismicImage, ButtonNuxt, Button } from '@swegaming-ab/vue-components';
import ButtonTarget from '@/components/buttons/ButtonTarget';

const components = {
    Section,
    Button,
    ButtonNuxt,
    ButtonTarget,
    PrismicImage,
    RichText
};

Object.entries(components).forEach(([name, component]) => {
    Vue.component(name, component);
});
