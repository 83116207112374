export default function navigationQuery() {
    let query = `{
        navigation {
            ...navigationFields
            body {
                ...on mega_menu {
                    non-repeat {
                        mega_menu {
                            ...mega_menuFields
                        }
                    }
                }
                ...on dropdown_menu {
                    non-repeat {
                        ddm_text
                    }
                    repeat {
                        ...repeatFields
                    }
                }
                ...on link {
                    non-repeat {
                        ...non-repeatFields
                    }
                }
            }
        }
    }`;
    return query.replace(/[^\S\r\n]/g, '');
}