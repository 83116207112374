<template>
    <nuxt-link
        :to="$prismic.linkResolver(document.primary.sl_link)"
        :class="{active: show}"
        class="nav-list-item__link"
    >
        {{ document.primary.sl_text }}
    </nuxt-link>
</template>

<script>
import ExpandDropdownLogic from '@/components/includes/mixins/ExpandDropdownLogic.js';
export default {
    mixins: [
        ExpandDropdownLogic
    ],
    props: {
        document: {
            type: Object,
            required: true
        }
    }
};
</script>