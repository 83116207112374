<template>
    <nav
        class="nav"
        :class="{ scrolled: scrolled }"
    >
        <div class="nav-logo">
            <nuxt-link to="/">
                <img
                    :src="favIcon"
                    alt="favicon"
                    loading="eager"
                    class="nav-logo__image"
                >
            </nuxt-link>
        </div>

        <ul class="nav-list">
            <li
                v-for="(item, index) in body"
                :key="`item${index}`"
                class="nav-list-item"
            >
                <dropdown-menu
                    v-if="item.slice_type === 'dropdown_menu'"
                    :document="item"
                    :class="{show: mobile}"
                    :slice-open="currentChildSliceOpen"
                    @expandedSlice="updateSliceId"
                />

                <mega-menu 
                    v-else-if="item.slice_type === 'mega_menu'" 
                    :document="item"
                    :slice-open="currentChildSliceOpen"
                    :class="{show: mobile}"
                    @expandedSlice="updateSliceId"
                />
                
                <nav-link
                    v-else
                    :document="item"
                    :class="{show: mobile}"
                />
            </li>
        </ul>

        <div 
            v-if="ctaEnabled" 
            class="nav-cta"
        >
            <ButtonNuxt 
                :link="cta.link"
                size="sm"
                background="green"
                :text="cta.text"
            />
        </div>

        <div class="nav-toggle">
            <i
                class="nav-toggle__btn"
                :class="{open: mobile}"
                @click="mobile =! mobile, toggle()"
            />
        </div>

        <div
            v-if="mobile"
            class="nav-overlay"
            @click="mobile = false, toggle()"
        />
    </nav>
</template>

<script>
import MegaMenu from '@/components/includes/components/MegaMenu.vue';
import ExpandDropdownLogic from '@/components/includes/mixins/ExpandDropdownLogic.js';
import DropdownMenu from '@/components/includes/components/DropdownMenu.vue';
import NavLink from '@/components/includes/components/NavLink.vue';
export default {
    components: {
        MegaMenu,
        DropdownMenu,
        NavLink
    },
    mixins: [
        ExpandDropdownLogic
    ],
    props: {
        favIcon: {
            type: String,
            required: false,
            default: '@/static/favicon.png'
        },
        offset: {
            type: Number,
            required: false,
            default: 600
        }
    },
    data() {
        return {
            scrolled: false,
            mobile: false,
            // Prismic data
            body: this.$store.state.navigation.body,
            cta: {
                link: this.$store.state.navigation.cta_link,
                text: this.$store.state.navigation.cta_text
            }
        };
    },
    computed: {
        ctaEnabled() {
            return this.cta.link && this.cta.link.id && this.cta.text;
        }
    },
    watch: {
        mobile() {
            document.body.style.overflow = this.mobile ? 'hidden' : '';
        },
        '$route'() {
            this.mobile = false;
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll);
    },
    beforeMount() {
        document.body.style.overflow = 'visible';
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        toggle() {
            this.show = ! this.show;
            if (this.show === false) {
                this.currentChildSliceOpen = '';
            }
        },

        onScroll(e) {
            this.scrolled = window.scrollY > this.offset;
        }
    }
};
</script>
