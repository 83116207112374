<template>
    <a
        v-if="link.data.redirect_enabled"
        :href="$helpers.getRedirectUrl(link, placement, position)"
        target="_blank"
        class="btn"
        :class="[
            'btn-' + background,
            'btn-' + size
        ]"
        @click="$emit('click')"
    >
        {{ text }}
        <i v-if="icon" :class="icon" class="" />
    </a>
</template>
<script>
export default {
    props: {
        background: {
            type: String,
            default: '',
            required: false
        },
        size: {
            type: String,
            default: '',
            required: false
        },
        icon: {
            type: String,
            required: false,
            default: String
        },
        link: {
            type: Object,
            required: true,
            default: () => {}
        },
        text: {
            type: String,
            required: false,
            default: 'Click me!'
        },
        placement: {
            type: String,
            required: false, 
            default: ''
        },
        position: {
            type: Number,
            required: false,
            default: 0
        }
    }
};
</script>
