<template>
    <footer class="footer">
        <div class="footer-newsletter section">
            <div v-if="submitted" class="rich-text">
                <h5>{{ $translate('footer_sign_up_to_newsletter_success_message', 'Du har nu anmält dig till nyhetsbrevet') }}</h5>
            </div>
            <div v-else class="rich-text">
                <h5>{{ $translate('footer_sign_up_to_newsletter_h5', 'Registrera dig för vårt nyhetsbrev') }}</h5>
                <p>{{ $translate('footer_sign_up_to_newsletter_p', 'Få tips, råd och erbjudanden direkt på mailen! Gör som våra 17 327 prenumeranter och registrera dig redan idag.') }}</p>
                
                <div class="form-container">
                    <input v-model="email" type="email" :placeholder="$translate('footer_email_placeholder', 'namn.efternamn@epost.se')">

                    <button
                        class="btn btn-md btn-green"
                        @click="submit"
                    >
                        {{ $translate('cta_sign_up_to_newsletter', 'Registrera dig') }}
                    </button>
                </div>
                <div 
                    v-if="$translate('footer_sign_up_disclaimer')" 
                    class="disclaimer" 
                    v-html="$prismic.asHtml($translate('footer_sign_up_disclaimer'))" 
                />
            </div>
        </div>
        <div class="footer-links section">
            <div class="footer-links__inner section-width-sm">
                <h2>{{ $prismic.asText(data.common_loan_amount_h2) }}</h2>

                <ul>
                    <li
                        v-for="(iterator, index) in data.loan_page"
                        :key="index"
                    >
                        <nuxt-link :to="$prismic.linkResolver(iterator.page)">{{ iterator.page_link_text }}</nuxt-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="footer-locales">
            <ul>
                <li v-for="(locale, index) in locales" :key="index">
                    <a :href="locale.domain">
                        <img :src="locale.icon" :alt="locale.title" loading="lazy"> 
                        <span>{{ locale.title }}</span>
                    </a>
                </li>
            </ul>
        </div>

        <div class="footer-inner section-width-md">
            <div class="footer-inner__column">
                <div v-html="$prismic.asHtml(data.short_info)" />
                <div v-if="$validateText(data.facebook.url) && $validateText(data.tiktok.url)" class="social-media">
                    <a 
                        v-if="$validateText(data.facebook.url)" 
                        :href="data.facebook.url" 
                        target="_blank"
                        class="facebook"    
                    >
                        <i class="fa-brands fa-square-facebook fa-xl" />
                    </a>
                    <a 
                        v-if="$validateText(data.tiktok.url)" 
                        :href="data.tiktok.url" 
                        target="_blank"
                        class="tiktok"
                    >
                        <i class="fa-brands fa-tiktok fa-xl" />
                    </a>
                </div>
            </div>

            <Column
                :header="data.link_column_1_header"
                :items="data.link_column_1_links"
            />

            <Column
                :header="data.link_column_2_header"
                :items="data.link_column_2_links"
            />
            <Column
                :header="data.news"
                :news-items="news"
                :all-news-link="data.all_news_link"
                :all-news-link-text="data.all_news_link_text"
            />
        </div>

        <div class="footer-boxes">
            <div class="footer-boxes__inner section-width-lg">
                <div
                    v-for="(box, index) in data.info_boxes"
                    :key="index"
                    class="box"
                >
                    <a
                        v-if="box.image_link.url"
                        :href="box.image_link.url"
                        :target="box.image_link.target"
                    >
                        <PrismicImage
                            v-if="box.image.url"
                            :img="box.image"
                            w="80"
                            h="40"
                        />
                    </a>

                    <PrismicImage
                        v-else-if="box.image.url"
                        :img="box.image"
                        w="80"
                        h="40"
                    />

                    <span>{{ box.header }}</span>
                    <span v-html="$prismic.asHtml(box.text)" />
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import axios from 'axios';
import Column from '@/components/includes/components/Column.vue';
import locales from '@/config/locales.config.js';
import { replace } from '@swegaming-ab/nuxtjs-helpers';
export default {
    components: { Column },
    data() {
        return {
            email: '',
            submitted: false,
            locales: locales,
            news: []
        };
    },
    async fetch() {
        const response = await this.$prismic.api.query(
            [
                this.$prismic.predicates.at('document.type', 'post'),
                this.$prismic.predicates.at('my.post.post_category', 'nyheter') 
            ],
            {
                lang: process.env.PRISMIC_LOCALE,
                orderings: '[ document.first_publication_date desc ]',
                pageSize: 5
            }
        );
        this.news = replace(response.results);
    },
    fetchKey: '5-latest-news',
    computed: {
        data() {
            return this.$store.state.footer;
        },
    },
    methods: {
        submit() {
            const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (this.email.match(validEmailRegex)) {
                axios
                    .post('https://api.broker.compary.com/users', {
                        email: this.email,
                        guide_id: process.env.BROKER_GUIDE_ID,
                        tracking: this.$tracking.getTrackingObject(),
                        redirect_url: null,
                    })
                    .then(() => {
                        this.submitted = true;
                        this.$event.newsletterSubscribed();
                    });
            }
            else {
                console.log('not matching');
            }
        }
    }
};
</script>
<style lang="scss" scoped>
    .footer {
        @include flex;
        background: #222;
        color: #fff;

        &-newsletter {
            width: 100%;
            background: $white;
            color: $font;
            // border-top: 5px solid $cream;
            text-align: center;
            box-shadow: 1px 1px  #000 #000;

            div {
                max-width: 600px;
            }

            .disclaimer, .disclaimer ::v-deep p {
                width: 100%;
                font-size: 0.85em;
                margin-top: 20px;
                line-height: 18px;
                color: #999;
            }

            .disclaimer ::v-deep p > a {
                color: $green;
            }

            button {
                width: 100%;
                margin-top: 5px;

                @media screen and (min-width: 1100px) {
                    width: auto;
                    margin-top: 0;
                    margin-left: 5px;
                }
            }
        }

        &-locales {
            width: 100%;
            background: #292929;
            padding: 20px 0;

            display: flex;
            justify-content: center; 
            align-items: center;

            ul {
                list-style: none; 
                display: flex;
                
                li {
                    margin-right: 40px;
                    a {
                        color: #fff;
                        // font-size: .8em;
                        text-decoration: none;

                        display: flex; 
                        align-items: center;
                        justify-content: center;

                        img {
                            margin-right: 10px;
                            width: 30px; height: 20px;
                            border-radius: 3px;
                        }
                    }
                }
            }
        }

        &-links {
            width: 100%;
            @include flex;
            background: $lightgray;

            &__inner {
                @include flex;

                h2 {
                    color: $font;
                    @include spacing(margin, 6, bottom);
                }

                ul {
                    @include flex;
                    width: 100%;

                    li {
                        @include spacing(margin, 2);

                        a {
                            font-family: $fontHeader;
                            text-decoration: none;
                            color: $font;
                            @include font-size(16px);

                            &:hover { text-decoration: underline; }
                        }
                    }
                }
            }
        }

        &-inner {
            @include grid(1, 40px);
            margin-top: 40px;
            max-width: calc( 100% - 40px );
            text-align: center;

            @include device(pad) {
                text-align: left;
                margin-top: 80px;
                @include grid(4, 30px);

            }


            &__column::v-deep {
                p:first-child {
                    @include spacing(margin,4,bottom);
                }
                p:last-child {
                    @include font-size(12px, 8px);
                }
                .social-media {
                    margin-top: 1rem;
                    display: flex;
                    @include device(mobile) {
                        justify-content: center;
                    }
                    column-gap: 10px;
                    .facebook, 
                    .tiktok {
                        color: $white;
                    }
                    .facebook:hover,
                    .tiktok:hover {
                        transition: 0.15s ease-in-out;
                        transform: scale(1.1);
                    } 
                }
            }
        }

        &-boxes {
            width: 100%;
            @include flex;
            background: #292929;
            margin-top: 80px;
            padding: 20px;
            box-sizing: border-box;

            &__inner {
                @include flex;
            }

            .box {
                @include flex;
                box-sizing: border-box;
                padding: 15px;
                color: rgba(255, 255, 255, .6);
                width: 100%;
                text-align: center;

                @include device(pad) {
                    width: 140px;
                }

                span::v-deep {
                    @include flex;
                    @include font-size(12px, 4px);
                    width: 100%;
                    font-weight: bold;
                    p {
                        @include font-size(12px, 4px);
                    }
                }

                span:first-child {
                    font-weight: normal;
                    margin-bottom: 10px;
                }
            }
        }
    }
</style>
