export default [
    {
        country: 'Sweden',
        title: 'Lånen.se',
        domain: 'https://www.lånen.se',
        prismic: 'sv-se',
        hreflang: 'sv-se',
        icon: '/flags/sv.png'
    },
    {
        country: 'Norway',
        title: 'Lånene.no',
        domain: 'https://www.lånene.no',
        prismic: 'no',
        hreflang: 'no-no',
        icon: '/flags/no.png'
    },
];