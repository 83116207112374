<template>
    <ul v-if="header" class="footer-inner__column">
        <li>{{ header }}</li>

        <template v-if="newsItems.length">
            <li
                v-for="(iterator, index) in newsItems"
                :key="index"
            >
                <nuxt-link :to="$prismic.linkResolver(iterator)">{{ iterator.data.title }}</nuxt-link>
            </li>
            <li class="page-link">
                <nuxt-link :to="$prismic.linkResolver(allNewsLink)">{{ allNewsLinkText }}</nuxt-link>
            </li>
        </template>

        <template v-else>
            <li
                v-for="(iterator, index) in items"
                :key="index"
            >
                <nuxt-link :to="$prismic.linkResolver(iterator.link)">{{ iterator.link_text }}</nuxt-link>
            </li>
        </template>
    </ul>
</template>

<script>
export default {
    props: {
        header: {
            type: String,
            required: false,
            default: ''
        },
        items: {
            type: Array,
            required: false,
            default: () => []
        },
        newsItems: {
            type: Array,
            required: false,
            default: () => []
        },
        allNewsLink: {
            type: Object,
            required: false,
            default: () => {}
        },
        allNewsLinkText: {
            type: String,
            required: false,
            default: ''
        },
    }
};
</script>

<style lang="scss">
    .footer-inner__column {
        li {
            margin: 5px 0;

            &:first-child { margin: 0 0 15px 0; }

            a {
                font-size: 12px;
                color: #fff;
                text-decoration: none;

                &:hover { text-decoration: underline; }
            }
        }

        li.page-link {
            font-weight: bold;
            margin-top: 20px;
        }
    }
</style>
