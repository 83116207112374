<template>
    <div 
        class="nav-list-item__dropdown" 
        :class="{active: show}"
        @mouseenter="onEnter()"
        @mouseleave="onLeave()"
    >
        <span class="ddm_text" @click="toggle">{{ dropdownText }}</span>
        <ul
            v-show="show"
            class="dropdown"
        >
            <li
                v-for="(link, linkIndex) in document.items"
                :key="`linkDropdown${linkIndex}`"
                :class="{ child: link.is_child }"
            >
                <nuxt-link :to="$prismic.linkResolver(link.ddm_link)">
                    <PrismicImage
                        v-if="link.ddm_link.data && link.ddm_link.data.icon"
                        :img="link.ddm_link.data.icon"
                        :alt="link.ddm_link_text"
                        w="50"
                        h="50"
                    />
                    <span>{{ link.ddm_link_text }}</span>
                </nuxt-link>
            </li>
        </ul>
    </div>
</template>

<script>
import ExpandDropdownLogic from '@/components/includes/mixins/ExpandDropdownLogic.js';
export default {
    mixins: [
        ExpandDropdownLogic
    ],
    props: {
        document: {
            type: Object,
            required: true
        }
    },
    computed: {
        dropdownText() {
            return this.document.primary.ddm_text;
        }
    }
};
</script>