import Vue from 'vue';
Vue.prototype.$helpers = {

    // create helpers that you can access
    // through the whole application
    filterEmptyRelations: (relation) => {
        let key = Object.keys(relation)[0];
        return relation[key] && relation[key].id;
    },

    filterEmptyElements: (element) => {
        let key = Object.keys(element)[0];
        return element[key];
    },

    // TODO: probably the ugliest code I've ever written
    getRedirectUrl: (lender, placement = '', position = 0) => {

        if (placement && position && position > 0) {
            return `/out/${lender.id}/?placement=${placement}&position=${position}`;
        }

        if (placement) {
            return `/out/${lender.id}/?placement=${placement}`;
        }

        if (position && position > 0) {
            return `/out/${lender.id}/?position=${position}`;
        }

        return '/out/' + lender.id + '/';
    },

    boolean: (bool) => {
        if (bool) {
            return 'Ja';
        }

        return 'Nej';
    },
};
