<template>
    <Section
        class="banner banner-404"
        width="md"
        background="cream"
    >
        <div class="container">
            <img
                src="~assets/images/404.png"
                alt="Lånen.se"
                class="mascot"
                loading="eager"
            >
        </div>

        <div class="container">
            <h1>{{ $translate('404_error_h1', 'Hoppsan! Sidan hittades inte.') }}</h1>
            <div v-if="$translate('404_error_message_p')" class="rich-text" v-html="$prismic.asHtml($translate('404_error_message_p'))" />
        </div>
    </Section>
</template>
<script>
export default {
    layout: 'site',
    head() {
        return {
            title: 'Lånen.se'
        };
    }
};
</script>
<style lang="scss" scoped>
section::v-deep {
    .section-inner {
        @include flex;

        .mascot {
            @include size(300px, auto);
            margin: auto;
        }

        .container {

            &:first-child {
                width: 100%;

                @include device(pad) {
                    flex: 1;
                    width: auto;
                }
            }

            &:last-child {
                width: 100%;

                @include device(pad) {
                    flex: 2;
                    width: auto;
                }

                h1 {
                    color: $green;
                    margin-bottom: 20px;
                }
            }
        }
    }
}
</style>
