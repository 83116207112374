import { size, keyBy } from 'lodash';
import { replace as replaceHelper, queryAll } from '@swegaming-ab/nuxtjs-helpers';
import navigationQuery from '@/plugins/navigation-query.js';

export const state = () => ({
    navigation: {},
    settings: {},
    footer: {},
    lenders: {},
    lendersCorporate: {},
    lendersMortgage: {},
    authors: [],
    lists: [],
    latestLenderUserRatings: [],
    translations: {},
    lendersToCompare: [],
});

export const mutations = {
    SET_STATE(state, {key, value}) {
        state[key] = replaceHelper(value);
    },

    ADD_LENDER_TO_COMPARE(state, lenderId) {
        state.lendersToCompare.push(lenderId);
    },

    REMOVE_LENDER_FROM_COMPARE(state, lenderId) {
        for (let i = 0; i < state.lendersToCompare.length; i++) {
            if (state.lendersToCompare[i] === lenderId) {
                state.lendersToCompare.splice(i, 1);
                break;
            }
        }
    },

    CLEAR_LENDERS_TO_COMPARE(state) {
        state.lendersToCompare.splice(0);
    }
};

export const actions = {
    async fetchStates({ commit }, {$prismic}) {
        try {

            if (size(this.state.navigation) === 0) {
                const navigation = await $prismic.api.getSingle('navigation', {
                    lang: process.env.PRISMIC_LOCALE, graphQuery: navigationQuery()
                });

                commit('SET_STATE', {
                    key: 'navigation',
                    value: navigation.data
                });
            }

            if (size(this.state.translations) === 0) {
                const translations = await $prismic.api.getSingle('translations', { 
                    lang: process.env.PRISMIC_LOCALE 
                });

                Object.keys(translations.data).forEach(key => {
                    if (! translations.data[key]) {
                        delete translations.data[key];
                    }
                });

                if (translations) {
                    commit('SET_STATE', {
                        key: 'translations',
                        value: translations.data
                    });
                }
            }

            if (size(this.state.settings) === 0) {
                const settings = await $prismic.api.getSingle('settings', {
                    lang: process.env.PRISMIC_LOCALE,
                });

                commit('SET_STATE', {
                    key: 'settings',
                    value: settings.data
                });
            }

            if (size(this.state.footer) === 0) {
                const footer = await $prismic.api.getSingle('footer', {
                    lang: process.env.PRISMIC_LOCALE,
                });

                footer.data.loan_page.forEach(iterator => {
                    cleanUpLinkData(iterator.page);
                });

                commit('SET_STATE', {
                    key: 'footer',
                    value: footer.data
                });
            }

            if (size(this.state.lenders) === 0) {
                const lenders = await queryAll(
                    $prismic.api,
                    $prismic.predicates.at('document.type', 'lender'), 
                    { lang: process.env.PRISMIC_LOCALE }
                );

                lenders.forEach(lender => {

                    cleanUpDocumentData(lender);
                    delete lender.data.seo_title;
                    delete lender.data.seo_description;
                    delete lender.data.author;
                    delete lender.data.sources;
                    delete lender.data.banner_text;
                    delete lender.data.about_lender;
                    delete lender.data.loan_terms;
                    delete lender.data.how_to_apply;
                    delete lender.data.service_security;
                    delete lender.data.summary;
                    delete lender.data.pros;
                    delete lender.data.cons;
                    delete lender.data.similar_loans;
                    delete lender.data.test_h2;
                    delete lender.data.test_p;
                    delete lender.data.test_score;
                    delete lender.data.test_date;
                    delete lender.data.test_amount;
                    delete lender.data.test_loan_duration;
                    delete lender.data.test_about_tester;
                    delete lender.data.test_review;
                    delete lender.data.faq_h2;
                    delete lender.data.faq_items;

                    delete lender.data.core_api.core_data.source_sales_stats;

                    lender.data.connected_banks.forEach(iterator => {
                        cleanUpLinkData(iterator.lender);
                    });
                });

                commit('SET_STATE', {
                    key: 'lenders',
                    value: keyBy(lenders, 'id')
                });
            }

            if (size(this.state.lendersCorporate) === 0) {
                const lendersCorporate = await queryAll(
                    $prismic.api,
                    $prismic.predicates.at('document.type', 'lender_corporate'),
                    { lang: process.env.PRISMIC_LOCALE }
                );

                lendersCorporate.forEach(lender => {

                    cleanUpDocumentData(lender);

                    delete lender.data.seo_title;
                    delete lender.data.seo_description;
                    delete lender.data.banner_text;
                    delete lender.data.about_lender;
                    delete lender.data.loan_terms;
                    delete lender.data.how_to_apply;
                    delete lender.data.service_security;
                    delete lender.data.summary;
                    delete lender.data.pros;
                    delete lender.data.cons;
                    delete lender.data.similar_loans;
                    delete lender.data.test_score;
                    delete lender.data.test_date;
                    delete lender.data.test_amount;
                    delete lender.data.test_loan_duration;
                    delete lender.data.test_about_tester;
                    delete lender.data.test_review;

                    delete lender.data.core_api.core_data.source_sales_stats;
                });

                commit('SET_STATE', {
                    key: 'lendersCorporate',
                    value: keyBy(lendersCorporate, 'id')
                });
            }

            if (size(this.state.lendersMortgage) === 0) {
                const lendersMortgage = await queryAll(
                    $prismic.api,
                    $prismic.predicates.at('document.type', 'lender_mortgage'),
                    { lang: process.env.PRISMIC_LOCALE }
                );

                lendersMortgage.forEach(lender => {
                    cleanUpDocumentData(lender);
                });

                commit('SET_STATE', {
                    key: 'lendersMortgage',
                    value: keyBy(lendersMortgage, 'id')
                });
            }

            if (size(this.state.authors) === 0) {
                const authors = await queryAll(
                    $prismic.api,
                    $prismic.predicates.at('document.type', 'author'), 
                    { lang: process.env.PRISMIC_LOCALE }
                );
                
                authors.forEach(author => {
                    cleanUpDocumentData(author);

                    delete author.data.seo_title;
                    delete author.data.seo_description;
                });

                commit('SET_STATE', {
                    key: 'authors',
                    value: keyBy(authors, 'id')
                });
            }

            if (size(this.state.lists) === 0) {
                const lists = await queryAll(
                    $prismic.api,
                    $prismic.predicates.at('document.type', 'list'),
                    { lang: process.env.PRISMIC_LOCALE }
                );

                lists.forEach(list => {
                    cleanUpDocumentData(list);

                    list.data.lenders.forEach(iterator => {
                        cleanUpLinkData(iterator.lender);
                    });
                });

                commit('SET_STATE', {
                    key: 'lists',
                    value: keyBy(lists, 'id')
                });
            }

            if (size(this.state.latestLenderUserRatings) === 0) {
                let ratings = [];

                Object.values(this.state.lenders).forEach(lender => {
                    if (lender.data.core_api && lender.data.core_api.ratings) {
                        lender.data.core_api.ratings.data.forEach(rating => {
                            ratings.push(Object.assign({
                                lenderId: lender.id
                            }, rating));
                        });
                    }
                });

                ratings.sort((r1, r2) => r2.id - r1.id);
                ratings = ratings.slice(0, 5);

                commit('SET_STATE', {
                    key: 'latestLenderUserRatings',
                    value: ratings
                });
            }
        }
        catch (e) {
            console.log(e);
        }
    }
};

const cleanUpDocumentData = (document) => {
    delete document.url;
    delete document.href;
    delete document.tags;
    delete document.slugs;
    delete document.linked_documents;
    delete document.alternate_languages;
    delete document.first_publication_date;
};

const cleanUpLinkData = (link) => {
    delete link.tags;
    delete link.lang;
    delete link.slug;
    delete link.first_publication_date;
    delete link.last_publication_date;
    delete link.isBroken;
};